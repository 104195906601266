import * as React from "react"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import { StaticImage } from "gatsby-plugin-image"

import { Container, Row, Col } from "react-bootstrap"

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Canais" />
      <div className="d-flex flex-column min-vh-100">
        <div className="store flex-grow-1">
          <Container>
            <Row className="mt-2">
              <Col>
                <StaticImage
                  src="../images/logo.png"
                  height={55}
                  alt="Logo"
                  placeholder="tracedSVG"
                />
              </Col>
            </Row>
          </Container>
          <Container className="store-content">
            <Row className="align-items-center h-100">
              <Col md={6}>
                <div className="text-wrapper">
                  <h1 className="gradient-text font-weight-300">
                    Gostaríamos.
                  </h1>
                </div>
                <div className="text-wrapper">
                  <h1 className="gradient-text font-weight-700">
                    De ter você sempre
                  </h1>
                </div>
                <div className="text-wrapper">
                  <h1 className="gradient-text font-weight-300">
                    Nos acompanhando
                  </h1>
                </div>
                <p className="mt-2">
                  Escolha o canal digital da sua preferência e fique por dentro
                  das novidades da Unnit.
                </p>
                <Row className="mt-5">
                  <Col md={2} xs={6} className="mb-3 text-right text-md-center">
                    <a
                      href="https://www.facebook.com/unnit.com.br/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        src="../images/canais/logo_facebook.png"
                        height={75}
                        alt="Facebook"
                        placeholder="tracedSVG"
                      />
                    </a>
                  </Col>
                  <Col md={2} xs={6} className="mb-3 text-left text-md-center">
                    <a
                      href="https://www.instagram.com/insta.unnit/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        src="../images/canais/logo_instagram.png"
                        height={75}
                        alt="Instagram"
                        placeholder="tracedSVG"
                      />
                    </a>
                  </Col>
                  <Col md={2} xs={6} className="mb-3 text-right text-md-center">
                    <a
                      href="https://www.linkedin.com/company/unnit/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        src="../images/canais/logo_linkedin.png"
                        height={75}
                        alt="LinkedIn"
                        placeholder="tracedSVG"
                      />
                    </a>
                  </Col>
                  <Col md={2} xs={6} className="mb-3 text-left text-md-center">
                    <a
                      href="https://unnit.com.br"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        src="../images/canais/logo_site.png"
                        height={75}
                        alt="Site"
                        placeholder="tracedSVG"
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="cta-2">
          <Container>
            <Row className="half-padding-top half-padding-bottom align-items-center justify-content-center">
              <Col md={1} className="d-none d-md-block text-center">
                <StaticImage
                  src="../images/canais/icon_carrinho.png"
                  height={55}
                  alt="Logo"
                  placeholder="tracedSVG"
                />
              </Col>
              <Col md={6} xs={12} className="text-center text-md-left">
                <h2 className="font-weight-300">
                  Acesse nossa{" "}
                  <span className="font-weight-700">loja virtual</span>.
                </h2>
              </Col>
              <Col md={3} className="text-center text-md-left">
                <a
                  className="btn btn-tertiary"
                  href="https://loja.unnit.com.br"
                  target="_blank"
                  rel="noreferrer"
                >
                  Saiba mais
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="credits">
          <Container>
            <Row>
              <Col className="text-center">
                <p>
                  Desenvolvido por{" "}
                  <a
                    href="https://megusta.com.br"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Me Gusta Digital
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
